import React from "react";
import { Row, Col } from "react-bootstrap";

interface Props {
  children?: JSX.Element;
}

const ImgFrameSection = ({ children }: Props) => (
  <Row>
    <Col xs="8" className="mx-auto">
      <div className="demo-img-frame my-9 p-9">{children}</div>
    </Col>
  </Row>
);

export default ImgFrameSection;
